<template>
  <div class="materials">
    <UserData />
    <div class="materials__title">Настройки Моего AZ-MOST:</div>
    <PersonalityTest
      @save="save"
      v-if="!personality"
      @nosologyClick="nosologyClick"
      @changeDisease="changeDisease"
      @selectAll="selectAll"
    />
    <div class="materials__switcher">
      <div
        class="materials__switch"
        :class="{ materials__switch_active: personality }"
      >
        <input type="checkbox" v-model="personality" />
      </div>
      <div class="materials__switcher-label">
        Отказаться от персонализации контента в профиле
      </div>
    </div>
    <router-link
      class="button button_gray d-inline-flex px-6 mt-8"
      v-if="personality"
      :to="{ name: 'MainPage' }"
    >
      На главную
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PersonalityTest from "../../components/pageComponents/cabinet/PersonalityTest.vue";
import UserData from "../../components/pageComponents/cabinet/UserData.vue";
export default {
  components: { PersonalityTest, UserData },
  name: "Materials",
  data: () => ({
    personality: false,
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["changeUserInfo", "getRecommendations"]),
    async save(diseases, nosologys, nosologysList) {
      let diseasesArr = diseases.map((el) => el.value);
      let diseasesYm = diseases.map((el) => el.label).join(",");
      let nosologysYm = nosologysList.map((el) => el.title).join(",");
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "LK personolized content save", {
          "LK personolized content save": {
            "selected therapeutic areas": nosologysYm,
            "selected diseases": diseasesYm,
            ...this.$root.ymFields,
          },
        });
      }
      await this.changeUserInfo({
        id: this.user.id,
        fields: {
          specialities: nosologys,
          diseases: diseasesArr,
        },
      });
      this.getRecommendations(this.user.id);
      this.$router.push({ name: "Materials" });
    },
    nosologyClick(nosology) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "LK personal therapeutic areas select", {
          "LK personal therapeutic areas select": {
            "therapeutic area": nosology,
            ...this.$root.ymFields,
          },
        });
      }
    },
    changeDisease(dis, state) {
      ym(91468266, "reachGoal", "LK personal therapeutic areas select", {
        "LK personal therapeutic areas select": {
          disease: dis.label,
          ...this.$root.ymFields,
        },
      });
    },
    selectAll(state) {
      ym(91468266, "reachGoal", "LK personal therapeutic areas select", {
        "LK personal therapeutic areas select": {
          disease: state,
          ...this.$root.ymFields,
        },
      });
    },
  },
  watch: {
    async personality() {
      if (this.personality) {
        ym(91468266, "reachGoal", "LK personolized content reject", {
          "LK personolized content reject": {
            on: "",
            ...this.$root.ymFields,
          },
        });
        document.body.scrollIntoView({ behavior: "smooth" });
        await this.changeUserInfo({
          id: this.user.id,
          fields: {
            specialities: [],
            diseases: [],
          },
        });
        this.getRecommendations(this.user.id);
      } else {
        ym(91468266, "reachGoal", "LK personolized content reject", {
          "LK personolized content reject": {
            off: "",
            ...this.$root.ymFields,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.materials {
  background-color: #f8f8f8;
  padding-left: 32px;
  padding-top: 48px;
  padding-bottom: 80px;
  z-index: 1000;
  position: relative;
  @media screen and (max-width: 1220px) {
    padding-left: 0;
  }

  &__title {
    margin-bottom: 24px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;
    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__switcher {
    margin-top: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__switch {
    flex-shrink: 0;
    padding: 4px;
    width: 48px;
    height: 24px;
    background: #d2d2d2;
    border-radius: 12px;
    position: relative;
    transition: 0.3s;
    margin-right: 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #1f1f1f;
    &:hover {
      background-color: #cc99b9;
    }

    & input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
      z-index: 100;
    }

    &::before {
      position: absolute;
      top: 4px;
      left: 4px;
      transition: 0.3s;
      width: 16px;
      height: 16px;
      background: #ffffff;
      border-radius: 50%;
      content: "";
      display: block;
    }

    &_active {
      background-color: #830051;
      &::before {
        left: calc(100% - 20px);
      }
    }
  }
}
</style>